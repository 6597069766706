import useStore from '../Store/market_greeks_data_store';

export const customIndicatorsGetter = function (PineJS) {
  return Promise.resolve([
    {
      name: 'SR Levels Indicator',
      metainfo: {
        _metainfoVersion: 53,
        id: "SR Levels Indicator@tv-basicstudies-1",
        description: "SR Levels Indicator",
        shortDescription: "SR Levels",
        format: { type: "inherit" },
        linkedToSeries: false, // Not overlaying directly on the main chart
        is_price_study: true, // Not a price study
        plots: [
          { id: "call1", type: "line" },
          { id: "call2", type: "line" },
          { id: "call3", type: "line" },
          { id: "put1", type: "line" },
          { id: "put2", type: "line" },
          { id: "put3", type: "line" },
        ],
        defaults: {
          styles: {
            call1: {
              linestyle: 2, // Dashed line
              linewidth: 1,
              transparency: 0,
              color: "#26ff01", // Green for Call Rank 1
            },
            call2: {
              linestyle: 2,
              linewidth: 1,
              transparency: 0,
              color: "#26ff01", // Green for Call Rank 2
            },
            call3: {
              linestyle: 2,
              linewidth: 1,
              transparency: 0,
              color: "#26ff01", // Green for Call Rank 3
            },
            put1: {
              linestyle: 2, // Dashed line
              linewidth: 1,
              transparency: 0,
              color: "#f10000", // Red for Put Rank 1
            },
            put2: {
              linestyle: 2,
              linewidth: 1,
              transparency: 0,
              color: "#f10000", // Red for Put Rank 2
            },
            put3: {
              linestyle: 2,
              linewidth: 1,
              transparency: 0,
              color: "#f10000", // Red for Put Rank 3
            },
          },
        },
        styles: {
          call1: { title: "Call 1" },
          call2: { title: "Call 2" },
          call3: { title: "Call 3" },
          put1: { title: "Put 1" },
          put2: { title: "Put 2" },
          put3: { title: "Put 3" },
        },
        inputs: [], // No user input needed for fixed values
      },
      constructor: function () {
        this.init = function (ctx) {
          this._context = ctx;

          // Fetch Call and Put Strikes from Zustand store
          const { topCallStrikes, topPutStrikes } = useStore.getState().originalSRData || {};

          if (!topCallStrikes || topCallStrikes.length < 3 || !topPutStrikes || topPutStrikes.length < 3) {
            // console.error('Not enough Call or Put Strikes for Rank 1-3.');
            this._srData = [];
            return;
          }

          // Fetch spxData to align with its time values
          const spxData = useStore.getState().spxData || [];
          if (!spxData || spxData.length === 0) {
            // console.error('spxData is undefined or empty.');
            this._srData = [];
            return;
          }

          // Map `spxData` to PineJS-compatible format with Call and Put Ranks 1-3
          this._srData = {
            call1: spxData.map(entry => ({
              time: Math.floor(new Date(entry.date).getTime() / 1000), // Convert date to Unix timestamp
              value: Number(topCallStrikes[0].strike), // Rank 1 Call
            })),
            call2: spxData.map(entry => ({
              time: Math.floor(new Date(entry.date).getTime() / 1000),
              value: Number(topCallStrikes[1].strike), // Rank 2 Call
            })),
            call3: spxData.map(entry => ({
              time: Math.floor(new Date(entry.date).getTime() / 1000),
              value: Number(topCallStrikes[2].strike), // Rank 3 Call
            })),
            put1: spxData.map(entry => ({
              time: Math.floor(new Date(entry.date).getTime() / 1000),
              value: Number(topPutStrikes[0].strike), // Rank 1 Put
            })),
            put2: spxData.map(entry => ({
              time: Math.floor(new Date(entry.date).getTime() / 1000),
              value: Number(topPutStrikes[1].strike), // Rank 2 Put
            })),
            put3: spxData.map(entry => ({
              time: Math.floor(new Date(entry.date).getTime() / 1000),
              value: Number(topPutStrikes[2].strike), // Rank 3 Put
            })),
          };

          // console.log('Mapped SR Levels Data for Calls and Puts:', this._srData);
        };

        this.main = function (ctx) {
          const currentTime = Math.floor(ctx.symbol.time / 1000); // Convert to seconds

          if (
            !this._srData ||
            !this._srData.call1 ||
            this._srData.call1.length === 0 ||
            !this._srData.put1 ||
            this._srData.put1.length === 0
          ) {
            return [NaN, NaN, NaN, NaN, NaN, NaN]; // Return NaN if no data is available
          }

          // Find the closest matching `time` for each rank
          const findClosest = (levels, time) => {
            let closest = null;
            let smallestDiff = Infinity;

            levels.forEach(level => {
              const timeDiff = Math.abs(level.time - time);
              if (timeDiff < smallestDiff) {
                closest = level;
                smallestDiff = timeDiff;
              }
            });

            return closest ? closest.value : NaN;
          };

          return [
            findClosest(this._srData.call1, currentTime), // Call Rank 1
            findClosest(this._srData.call2, currentTime), // Call Rank 2
            findClosest(this._srData.call3, currentTime), // Call Rank 3
            findClosest(this._srData.put1, currentTime), // Put Rank 1
            findClosest(this._srData.put2, currentTime), // Put Rank 2
            findClosest(this._srData.put3, currentTime), // Put Rank 3
          ];
        };
      },
    },
  ]);
};























