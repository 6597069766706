import React, { useEffect, useRef } from 'react';
import CustomDataFeed from './CustomDataFeed';
import { customIndicatorsGetter } from './customIndicators';
import { customIndicatorsGetter as volatilityIndicatorGetter } from './volatility_indicator';
import { customIndicatorsGetter as srIndicatorGetter } from './SR_indicator';
import processSRData from './SR_series';
import useStore from '../Store/market_greeks_data_store';

const AdvancedMarketStateDashboard = () => {
  const spxData = useStore((state) => state.spxData);
  const spxGexData = useStore((state) => state.spxGexData);
  const vvixData = useStore((state) => state.vvixData);
  const topStrikesData = useStore((state) => state.topStrikes);
  const setOriginalSRData = useStore((state) => state.setOriginalSRData);
  const originalSRData = useStore((state) => state.originalSRData);
  const chartWidget = useRef(null);

  // Determine if the device is mobile
  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    if (topStrikesData.length > 0) {
      const { topCallStrikes, topPutStrikes } = processSRData(topStrikesData);
      setOriginalSRData('topCallStrikes', topCallStrikes);
      setOriginalSRData('topPutStrikes', topPutStrikes);
    }
  }, [topStrikesData, setOriginalSRData]);

  useEffect(() => {
    if (
      spxData.length > 0 &&
      spxGexData.length > 0 &&
      vvixData.length > 0 &&
      Object.keys(originalSRData).length > 0
    ) {
      loadChartWithIndicators(spxGexData, vvixData, originalSRData);
    }

    return () => {
      if (chartWidget.current) {
        const chart = chartWidget.current.activeChart();
        chart.onVisibleRangeChanged().unsubscribeAll(this);
        chart.crossHairMoved().unsubscribeAll(this);
      }
    };
  }, [spxData, spxGexData, vvixData, originalSRData]);

  const loadChartWithIndicators = (spxGexData, vvixData, srLevelsData) => {
    const dataFeed = new CustomDataFeed();

    const widget = new window.TradingView.widget({
      container: 'chartContainer',
      library_path: '/charting_library/',
      datafeed: dataFeed,
      symbol: 'SPX',
      interval: '1D',
      autosize: true,
      theme: 'Dark',
      overrides: {
          // 'paneProperties.background': '#000000',
          // 'paneProperties.backgroundType': 0,  // 0 for solid, 1 for gradient
          "paneProperties.backgroundGradientStartColor": "#000000",
          "paneProperties.backgroundGradientEndColor": "#131722",
          'paneProperties.vertGridProperties.color': 'rgba(173,173,173,0.06)',
          'paneProperties.horzGridProperties.color': 'rgba(173,173,173,0.06)',
          'scalesProperties.textColor': '#FFFFFF',
          'toolbarProperties.background': '#000000',
          'toolbarProperties.separator': '#444444',
      },
      disabled_features: isMobile
        ? [
            'scroll_past_end',
            'create_volume_indicator_by_default',
            'control_bar',
            'header_widget',
            'left_toolbar',
            'timeframes_toolbar',
            'edit_buttons_in_legend',
            'context_menus',
            'control_bar',
            'border_around_the_chart',
          ]
        : ['scroll_past_end', 'create_volume_indicator_by_default'],

      enabled_features: [
        'use_localstorage_for_settings',
        'save_chart_properties_to_local_storage',
        'canvas_support',  // Add this
        'chart_property_page_style'  // And this
    ],
      custom_indicators_getter: async (PineJS) => {
        const customIndicators = await customIndicatorsGetter(PineJS);
        const volatilityIndicators = await volatilityIndicatorGetter(PineJS);
        const srIndicators = await srIndicatorGetter(PineJS);
        return [...customIndicators, ...volatilityIndicators, ...srIndicators];
      },
    });

    widget.onChartReady(() => {
      const chart = widget.activeChart();
          // Add the CSS custom properties here
    widget.setCSSCustomProperty('--tv-color-pane-background', '#000000');
    widget.setCSSCustomProperty('--tv-color-toolbar-button-text', '#FFFFFF');

      chart.createStudy('SPX Market GEX', false, false, {
        offset: 0,
        source: 'spxGexData',
      });

      chart.createStudy('SPX Gamma Volatility Indicator', false, false, {
        offset: 0,
        source: 'vvixData',
      });

      chart.createStudy('SR Levels Indicator', false, false, {
        source: 'originalSRData',
      });
    });

    chartWidget.current = widget;
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        maxWidth: '100vw',
        maxHeight: '100vh',
        overflow: 'hidden',
      }}
    >
      <div
        id="chartContainer"
        style={{
          flex: 1,
          border: '1px solid #333',
          borderRadius: '8px',
          overflow: 'hidden',
        }}
      ></div>
    </div>
  );
};

export default AdvancedMarketStateDashboard;


























































































