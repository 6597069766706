// MarketStateWebsocketClient.js
import { useEffect } from 'react';
import useStore from '../Store/market_greeks_data_store';

const MarketStateWebsocketClient = () => {
  useEffect(() => {
    const wsUrl = process.env.REACT_APP_WEBSOCKET_URL || 'wss://market-state-wss-pro-990a4e46c5d1.herokuapp.com/';
    const ws = new WebSocket(wsUrl);

    ws.onopen = () => {
      console.log('Connected to WebSocket server');
      ws.send(JSON.stringify({ type: 'market-state-data' })); // Request initial market state data
    };

    ws.onmessage = (event) => {
      const response = JSON.parse(event.data);
      if (response.type === 'market-state-data-response') {
        const { setSpxData, setVixData, setMarketStatus, setTopStrikes, setVvixData, setSpxGexData } = useStore.getState();

        const toUnixTime = (dateStr) => new Date(dateStr).getTime(); // Convert to Unix timestamp in milliseconds

        const formatAndSortData = (data) =>
          data.map(item => ({
            ...item,
            time: toUnixTime(item.date),
          })).sort((a, b) => a.time - b.time); // Sort by Unix time in ascending order

        setSpxData(formatAndSortData(response.spxData));
        setVixData(formatAndSortData(response.vixData));
        setMarketStatus(formatAndSortData(response.marketStatus));
        setTopStrikes(formatAndSortData(response.topStrikes));
        setVvixData(formatAndSortData(response.vvixData));
        setSpxGexData(formatAndSortData(response.spxGexData));
      }
    };

    ws.onclose = (event) => {
      console.log(`WebSocket connection closed: code=${event.code}, reason=${event.reason}`);
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    return () => {
      console.log('Cleaning up WebSocket connection...');
      ws.close();
    };
  }, []);

  return null;
};

export default MarketStateWebsocketClient;





